import { useRouter } from "next/router";
import { useEffect } from "react";

export default function Custom404() {
  const router = useRouter();
  // redirect
  useEffect(() => {
    router.push("/");
  });
  return null;
}
